@use '../../partials/variables' as *;
@use '../../partials/mixins'as *;

// ===========================\\
//  #FOOTER
//============================\\

.footer {
    position: relative;
    z-index: 10;
    padding: 4rem 2rem 6rem;
    background-color: $color-tar-fade;

    &__resources {
        margin-bottom: 1rem;
        max-width: $content-max-width;
        width: 100%;
        margin: 0 auto;
    }

    &__contact {
        max-width: $content-max-width;
        width: 100%;
        margin: 0 auto;
    }

    &__title {
        font-family: 'Abel';
        color: White;
    }
}