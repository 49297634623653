// ===========================\\
//  #Colour variables
//============================\\

$color-sorbet: #b9d7b2;
$color-sorbet-light: #eaf3e7;
$color-lapis: #23828c;
$color-robin: #6aced9;
$color-kelly: #29a678;
$color-ceder: #24732f;
$color-tar: #0d0d0d;
$color-tar-fade: #252525;

// ===========================\\
//  #Layout variables
//============================\\

$content-max-width: 1366px;