@use '../../partials/variables' as *;
@use '../../partials/mixins'as *;

// ===========================\\
//  #PANEL
//============================\\

.panel {
    padding: 1.5rem;
    @include fade-in;

    @include tablet {
        padding: 2.5rem;
    }
    
    &:nth-child(4n+1) {
        position: relative;
        
        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: -0.5rem;
            left: 0;
            width: 100%;
            height: 110%;
            background-color: $color-kelly;
            transform: matrix( 1, -0.03, 0, 1, 0, 0 );
        }
    }

    &:nth-child(4n+2) {
        position: relative;
        
        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 110%;
            background-color: $color-robin;
            transform: matrix( 1, 0.03, 0, 1, 0, 0 );
        }
    }

    &:nth-child(4n+3) {
        position: relative;
        
        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 110%;
            background-color: $color-ceder;
            transform: matrix( 1, -0.04, 0, 1, 0, 0 );
        }
    }

    &:nth-child(4n+4) {
        position: relative;
        
        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: -0.25rem;
            left: 0;
            width: 100%;
            height: 110%;
            background-color: $color-lapis;
            transform: matrix( 1, 0.04, 0, 1, 0, 0 );
        }
    }


    &__content {
        position: relative;
        top: -3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        max-width: $content-max-width;
        width: 100%;
        padding: 1.5rem;
        margin: 0 auto;
        transition: transform 150ms ease;

        @include desktop {
            padding: 2.5rem;
        }

        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $color-sorbet-light;
            transform: matrix( 1, 0.01, -0.005, 1, 0, 0 );
            box-shadow: 0.25rem 0.25rem 0 rgba($color-tar,0.25);
        }   
        
        &:hover {
            transform: rotate(0.25deg) scale( 1.01);
            
        }

        &--small {
            width: fit-content;
            flex-direction: column;
        }
        
    }

    &__copy {
        flex-basis: 100%;

        @include tablet {
            flex-basis: 60%;
        }
        
    }

    &__title {
        position: relative;
        z-index: 10;
        margin-bottom: 1rem;
        font-family: 'Abel';
        font-size: 3rem;

        @include desktop {
            font-size: 3.5rem;
        }
    }

    &__title-two {
        display: inline-block;
    }

    &__title-three {
        position: relative;
        z-index: 10;
        margin-bottom: 1rem;
        font-family: 'Abel';
        font-size: 1.5rem;
    }

    &__body {
        position: relative;
        z-index: 9;
        margin-bottom: 2rem;
        font-size: 1.5rem;
        line-height: 2rem;

        @include desktop {
            font-size: 2rem;
            line-height: 2.75rem;
            padding-left: 4rem;
        }
    }

    &__image {
        position: relative;
        z-index: 8;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include tablet {
            flex-basis: 40%;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 75%;
            height: 100%;
            border-radius: 50%;
            margin: 0 auto;
            background: rgba($color-robin, 0.15);
        }

        
    }

    &__socials {
        position: relative;
        z-index: 10;
    }
}