@use './partials/typography' ;
@use './partials/variables' as *;
@use './partials/mixins' as *;

// ===========================\\
//  #Global Styles
//============================\\

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

svg {

  &:focus {
    outline: none;
  }
}

body { 
  font-family: "Bodoni Moda";
  color: $color-tar-fade;
  background-color: $color-tar-fade;
}

.image {
  max-width: 100%;
  width: 100%;
}

.link {
  text-decoration: none;
  font-family: 'Abel';

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: $color-kelly;
  }

  &--footer {
    display: block;
    color: white;
    
  }

}

.animation-window {
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 95%;
    border-radius: 10rem;
    background: $color-kelly;
    opacity: 0;
    transition: opacity 175ms;
  }
  

  &:hover::after {
    opacity: 1;
  }
}

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 65vh;
 

  &__icon {
    position: relative;
    bottom: -4rem;
    width: 20rem;
    height: 20rem;
    cursor: pointer;
    @include color-fade-animation;
    
  }
}

.react-share__ShareButton {
  margin-right: 1rem;
  transform: scale(1);
  transition: transform 150ms ease;

  &:hover {
    transform: scale(1.2);
  }

  &:focus {
    outline: none;
  }

  &:last-child {
    margin-right: 0;
  }
}
