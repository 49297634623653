@use '../../partials/variables' as *;
@use '../../partials/mixins'as *;

// ===========================\\
//  #HEADER
//============================\\

.header {
    position: relative;
    padding: 2.5rem 1rem 4rem;
    color: $color-sorbet-light;
    @include fade-in;

    @include tablet {
        padding: 3.5rem 0 6rem 2.5rem;
    }

    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: -2rem;
        left: 0;
        width: 100%;
        height: 110%;
        background-color: $color-tar-fade;
        transform: matrix( 1, -0.03, 0, 1, 0, 0 );
    }

    &__content {
        max-width: $content-max-width;
        width: 100%;
        margin: 0 auto;
    }

    &__title {
        position: relative;
        z-index: 10;
        margin-bottom: 2rem;
        font-family: "Abel";
        font-size: 6.5rem;
        font-weight: 100;
        line-height: 6.5rem;

        @include tablet {
            font-size: 7.5rem;
            line-height: 7.5rem;
        }

        @include desktop {
            font-size: 10rem;
            line-height: 10rem;
        }
    }

    &__super {
        display: block;
        font-size: 4rem;
        line-height: 5rem;

        @include tablet {
            font-size: 5rem;
            line-height: 6rem;
        }

        @include desktop {
            font-size: 7rem;
            line-height: 7.5rem;
        }
    }

    &__subtitle {
        position: relative;
        z-index: 10;
        font-weight: 700;
        
        @include tablet {
            font-size: 1.75rem;
            line-height: 2.5rem;
        }

        @include desktop {
            font-size: 2rem;
            line-height: 2.75rem;
        }
    }

    &__superscript {
        position: relative;
        top: -0.5rem;
        font-size: 1rem;
    }

    &__small-font {
        position: relative;
        z-index: 10;
    }
}

.title-box {
    position: relative;
    padding: 0.75rem;
    width: fit-content;    

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-kelly;
        transform: matrix( 1, -0.01, 0.04, 1, 0, 0 );
    }

}

.pattern-swatch {
    width: 40%;
    background-image: url(../../assets/images/pattern-tile.svg);
    // background-repeat: repeat;
    background-size: cover;
    box-shadow: 0.25rem 0.25rem 0 rgba($color-tar,0.25);;
}

